import React from 'react';
import '../App.css';

const Fade = ({
  show,
  children
}) => {
  const [shouldRender, setRender] = React.useState(show);

  React.useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  if (shouldRender) {
    return (
      <div
        style={{
          animation: `${show ? 'fadeIn' : 'fadeOut'} 3s`,
          animationTimingFunction: 'ease-in-out',
          position: 'relative',
          zIndex: 500
        }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    );
  }
  return null;
};

export default Fade;
