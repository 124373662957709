import React from 'react';
import './App.css';
import axios from 'axios';
import Fade from './components/Fade';

import mainLogo from './assets/main_logo.png';
import sideLogo from './assets/long_side_logo.png';
import emailCopy from './assets/email_copy.png';
import submit from './assets/submit.png';
import success from './assets/success_message.png';
import archivesTitle from './assets/archives.png';
import goBack from './assets/go_back.png';
import prologue from './assets/prologue.png';
import nightmare from './assets/waking_nightmare.png';

import video from './assets/background_video.mp4';

import { socials, archives } from './json';

function App() {
  const [email, setEmail] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const [showArchives, setShowArchives] = React.useState(false);
  const [archiveIndex, setArchiveIndex] = React.useState(undefined);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!email.trim()) return;
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_EMAIL_URL}`,
      data: { email: email },
      headers: { 'content-type': 'application/json' }
    }).then(() => {
      setSubmitted(true);
    }).catch(err => console.log(err));
  }

  const SilentVideo = () => {
    const videoRef = React.useRef(undefined);
    React.useEffect(() => {
      videoRef.current.defaultMuted = true;
    }, []);
    return (
      <video
        className="background-video"
        ref={videoRef}
        loop
        autoPlay
        muted
        playsInline
      >
        <source src={video} type="video/mp4" />
      </video>
    );
  }

  return (
    <div className="mobiius-top-container">
      <SilentVideo />
      <Fade show={true}>
        <div className="main-content-container">
          <img
            src={sideLogo} 
            alt="mobiius-side-logo" 
            className="mobiius-side-logo fade-in-main"
          />
          {showArchives ? (
            <div className="mobiius-archives-container">
              <img 
                src={archivesTitle} 
                alt="the archives"
                className="archives-logo"
              />
              <img
                src={goBack}
                alt="back button"
                className="archives-back-button"
                onClick={() => {
                  setShowArchives(false);
                  setArchiveIndex(undefined);
                }}
              />
              {archiveIndex !== undefined ? (
                <div className="archive-text-container">
                  {archives[archiveIndex].text.map((item, i) => (
                    <p 
                      key={`archive-${i}`}
                      className="archive-text"
                    >
                      {item}
                    </p>
                  ))}
                </div>
              ) : (
                <>
                  <div className="archives-grid">
                    <img 
                      src={prologue} 
                      alt="prologue"
                      className="archive-icon"
                      onClick={() => setArchiveIndex(0)}
                    />
                    <img
                      src={nightmare}
                      alt="waking nightmare"
                      className="archive-icon"
                      onClick={() => setArchiveIndex(1)}
                    />
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <div className="email-entry-container">
                <img
                  src={mainLogo} 
                  alt="mobiius-logo" 
                  className="mobiius-logo"
                  onClick={() => setShowArchives(true)}
                />
                {submitted
                  ? <img
                      className="mobiius-success-message"
                      src={success}
                      alt="submission successful, check your email for exclusive content."
                    />
                  :
                  <>
                    <img 
                      src={emailCopy}
                      alt="let us cure you. enter the fold."
                      className="email-copy-image"
                    />
                    <form onSubmit={handleFormSubmit}>
                      <input
                        type="email"
                        className="email-input"
                        placeholder="email"
                        value={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                      />
                      <button
                        type="submit" 
                        value="enter"
                        className="submit-button"
                      >
                        <img src={submit} alt="submit" />
                      </button>
                    </form>
                  </>
                }
                <div className="mobiius-socials">
                  {socials.map(item => (
                    <a
                      key={item.altText}
                      href={item.url}
                      rel="noopenener noreferrer"
                      target='_blank'
                    >
                      <img src={item.icon.default} alt={item.altText} />
                    </a>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </Fade>
    </div>
  );
}

export default App;
