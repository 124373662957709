module.exports = {
  socials: [
    { 
      url: 'https://www.instagram.com/mobiius_music/', 
      icon: require('../assets/instagram.png'),
      altText: 'instagram',
    },
    { 
      url: 'https://open.spotify.com/artist/0jHnzOGooKPzt7qA3Q32mw?si=rpmIZe4bRgKQCA7SCf-S1Q&dl_branch=1', 
      icon: require('../assets/spotify.png'),
      altText: 'spotify',
    },
    { 
      url: 'https://itunes.apple.com/ca/artist/mobiius/1365950155', 
      icon: require('../assets/apple_music.png'),
      altText: 'apple music',
    },
    { 
      url: 'https://www.youtube.com/channel/UC10dAwpJVGTbkZV94w9ECow?view_as=subscriber', 
      icon: require('../assets/youtube.png'),
      altText: 'youtube',
    },
    { 
      url: 'https://soundcloud.com/mobiius', 
      icon: require('../assets/soundcloud.png'),
      altText: 'soundcloud',
    },
    {
      url: 'https://mobiiusmusic.bandcamp.com/',
      icon: require('../assets/bandcamp.png'),
      altText: 'bandcamp'
    }
  ],
  archives: [
    {
      image: require('../assets/prologue.png'),
      text: [
        'Bit by bit, you feel yourself sink into the sweltering night that chokes this narrow alleyway.',
        'Neon lanterns illuminate lazy plumes of smoke and steam.\nYour breathing becomes labored, but you don’t care.',
        'Heavy shoes scrape wet gravel along as you shuffle past faceless denizens,',
        'some of whom you’ve surely met, and just as surely forgotten.',
        '-',
        'A wrought iron doorway appears on your left: squat and rounded, crusted with greenish rust.',
        'Throbbing noises emanate from inside.',
        'Temptation barely flickers across your consciousness before you enter,\nhoping to find soothing indulgences.',
        'Your mind is overwhelmed, and the thought of taking another step sober is almost too much to bear.',
        '-',
        'Once expensive and of high taste, the furniture in the club is now shabby and worn,\nshapes of coiling lovers pressed into the cushions.',
        'A lone woman sings on stage in a shoulderless black dress, her voice husky and robotic,\none panel of her face removed to reveal the steam-circuitry beneath. Bold of her.',
        'Dusty curtains muffle the air around you, and her soft crooning lulls you to a seat.',
        '-',
        'Deep in a velvet sofa, drink pressed against your temple to cool the beads of sweat,\nyou barely notice the pearlescent figure sliding up beside you',
        'A long, pointed tongue collects a few droplets from your cheek.',
        'Soft, warm breath floods your ear.',
        'Unconsciousness swallows you.',
        '...',
        'Awakening with a jolt, your surroundings have vanished.\nEither your eyes are gone or the room is impenetrably dark - impossible to tell.',
        'Your hands and feet meet what feels like cold marble.\nHairline fissures run between your fingertips as you clamber to stand.',
        'A wispy hand takes yours and leads you forward… at least, it feels like forward.',
        'The pumping vibrations you heard from outside are much louder now, scattering across hard walls.',
        '-',
        '“Where you’ve been living, the blind lead the blind” a voice at the end of the hand says.'
      ]
    },
    {
      image: require('../assets/waking_nightmare.png'),
      text: [
        'The tips of your stilettos hit the wet pavement with a volley of sharp clacks as you pull yourself out of the deep bucket seat, back into the heavy, hazy air of the outside world.',
        '“Same time next week, darling?”',
        '“Sure Jack, you know where to find me.”',
        'The worm. Just married and already visiting again. Not that you’d say anything; an easy client is an easy client.',
        'He pulls away as you saunter to a vending machine for a hot coffee. It clatters out and you rush it to your lips… tepid. Fantastic.',
        'Catching a glimpse of yourself in the shimmering glass of the machine, you notice your lipstick smudged nearly halfway across your cheek, and a small bruise forming at the base of your neck. That idiot, he should know better by now.',
        'Setting the coffee down, nimble, practiced fingers get to work on concealing the evidence of the last engagement, obscuring the bruise as best as possible and reforming a clean lip line.',
        'After six months working the East District, you almost have enough to pay off your debts and get out of the city, try to find something simpler. Maybe open a little boutique in the outskirts, get an automaton rabbit for company- after all, they’ve become quite affordable recently.',
        'The final touches of lipstick have barely been put in place before the reflection of a brushed silver limousine slips up behind you. Your face flushes a bit, it looks brand new. This could be just what you need.',
        'A deep breath fills your lungs. “You can do this.”',
        'With a practiced spin on the stiletto, you stride confidently to the opening window.',
        '“Hey handsome, got anything I can help you with?” The dark of the passenger compartment is almost impenetrable, but a gruff voice mumbles something and the door swings open.',
        '______',
        'The ride is uneasy, to say the least. The silhouette of the man beside you is huge, his shoulders nearly two seats wide, and his head mere millimeters from the ceiling. Any small talk or physical advances you attempt to make are curtly rebuffed.',
        'Before long, the limo comes to a stop in front of a squat apartment complex. Not the upscale hotel you were hoping for, but then again it makes sense. Whoever this guy is may want to keep his dealings away from prying eyes.',
        'He ducks out behind you as you exit, and hurriedly escorts you inside, through the lobby, and into an elevator, his massive paw of a hand pressed into the small of your back. Not a person in sight.',
        '______',
        'In the room, his full appearance is finally visible. A perfectly tailored charcoal suit drapes his massive, muscled frame. Head shaved clean and sharp, broad features. His deep-set eyes fall in shadow under a heavy brow, and thin lips press together into a permanent scowl.',
        'He motions for you to sit as he removes his jacket and sets to work at the table, emptying out a tin of what looks like tobacco in front of him.',
        'You watch with nervous apprehension. It’s not unusual for clients to be uninterested in conversation, but uninterested in conversation and your body? It’s bizarre.',
        'Finally, he turns and lights the newly rolled cigarette, releasing small jets from his nostrils.\n“I need it to relax. I’m sure you don’t mind.”',
        'It’s the first full full sentences he’s uttered.',
        '“I didn’t know you could still get real tobacco this far into the city, I haven’t seen it in ages.”',
        '“There are always ways to get what you want,” he grunts and holds it out to you with a raised eyebrow.',
        'Meeting the dull sparkle of his eyes, you take it. Hell, something to take the edge off doesn’t sound too bad right about now.',
        'As soon as the smoke hits your tongue, it’s clear that something is off. The dull, woody taste you anticipated is overpowered by a metallic tang.',
        'At that moment, the thread connecting your perception to reality is violently severed.',
        '______',
        '“A bit strong for you, eh?” he smirks.',
        '“What the… what the fuck is in this?” you stammer between racking coughs. The room starts to slide upward. Lightheaded buzzing gives way to a spiraling, pulsating dizziness.',
        '“Haaleng Tobacco,” his smile gets bigger and you can see the deep yellowing of his teeth. How does he seem unaffected?',
        'But then, even these musings disappear, you can’t remember what you’re doing here or what you’ve said, but you’re sure you need to act composed.',
        'Oh god, oh god, oh god, oh god, og hod, o, dhog, hgdo, thoughts spiral in on themselves and deteriorate, your body seems fully outside of you now. Or is it inside?',
        'Wait! Get yourself out of here!',
        'A burst of adrenaline lifts you to your feet and manages to stagger to the door. You grasp at the doorknob but it\'s melting; your vision has started to mirror the miasma that’s consumed your brain and any clarity you’d just found.',
        'Or was it now? Seems like forever ago. The door gets taller as your body freezes, are you on the floor now? Why? Why can’t you move, what can you say? Nothing is coming out, this isn’t normal. Another wave comes by and decimates your train of thought, swirling through your paralyzed form and up to a tightness on your wrist. Does it always feel like that? No, it’s him! He’s grabbed you and is dragging you back into the room. Your eyes are glued on the door, willing it to open, but it’s getting smaller, smaller. Just a pinprick now.',
        'Nothing.',
        '...',
        'You awaken on something cool and impossibly smooth. A far cry from the scratchy carpet of the apartment.',
        'The apartment! Memories come spilling into your head like a sink draining in reverse, disorganized at first, but quickly falling into order. You start to a seat and look around.',
        'Is he here with you?',
        'You wheel around, trying to take in your surroundings, but the darkness is absolute. You grasp at your clothes but they seem to be in place. Just then, you notice the subtle pulsing of the floor beneath you, and a barely audible rumble.',
        'The vibrations creep up your fingertips, arms, shoulders, neck, till you can feel them from the top of your skull to your gut. It’s almost hypnotically relaxing, you don’t even start when a soft hand is suddenly placed on your own.',
        '“Don’t worry, you’re safe now.” a wispy voice croons, seeming to come from all around you.',
        '“Where am I?”',
        '“Your new home. Welcome to the fold.”',
        '_____'
      ]
    }
  ],
};